<template>
  <v-list nav dense>
    <v-list-item-group v-model="locale" color="globalBlue">
      <v-list-item
        v-for="locale in locales"
        :key="locale.code"
        @click="setLocale(locale.code)"
        :value="locale.code"
      >
        <v-list-item-content>
          <v-list-item-title class="body-2 font-weight-regular">{{
            locale.name.toUpperCase()
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { getSupportedLocales } from "@/helpers/i18n/supported-locales";

export default {
  name: "LocaleSwitcher",
  data: () => ({
    locales: getSupportedLocales(),
    locale: "",
  }),
  methods: {
    setLocale(locale) {
      if (locale) {
        import(
          /* webpackChunkName: "locales-[request]" */ `@/locales/${locale}.json`
        ).then((msgs) => {
          this.$root.$i18n.setLocaleMessage(locale, msgs.default);
          this.$root.$i18n.locale = locale;
          localStorage.setItem("locale", locale);
        });
      }
    },
  },
  mounted() {
    this.locale = this.$i18n.locale;
  },
};
</script>

<style></style>
